<template>
  <v-card class="dashBoardCard">
    <v-card-title>
      <span>최근 한달 간 방문자 로그</span>
    </v-card-title>
      <v-data-table
        v-if="!loading"
        fixed-header
        density="compact"
        scrollable
        :headers="headersArr"
        :items="logList"
        item-value="name"
        class="SsEmpWorkPayTypeTb dashboardComponentTable fontNotoSans400"
        :items-per-page="-1"
        :items-per-page-options="[
          { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
        ]"
      >
        <template v-slot:item="{ item, index }">
          <tr>
            <td class="tal">{{ index + 1 }}. {{ item.compName }}</td>
            <td class="tal">{{ item.subject }}</td>
            <td class="tal">{{ item.readDate }}</td>
          </tr>
        </template>
      </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
import {  onMounted, ref } from "vue";
import axios from "axios";
const loading = ref(false);
const logList = ref([] as any[]);
const headersArr = ref([
  { title: "기관명", key: "compName", align: "start", sortable: true },
  { title: "메일제목", key: "subject", align: "start", sortable: true },
  { title: "접속일시", key: "readDate", align: "start", sortable: true },
] as any[]);

const getMailLog = async () => {
  try {
    loading.value === true;
    const response = await axios.get(`/api/getMailLog`);

    const responseData = response.data;
    if (responseData) {
      logList.value = responseData;
      console.log(logList.value, "logList.value");
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value === false;
  }
};
onMounted(async () => {
  await getMailLog();
});
</script>
