<template>
  <template v-if="changeUpdate">
    <v-card class="ckeditorCard" style="padding: 25px 60px; width: 80%; margin: auto; ">
      <v-btn @click="goToUpdateCancle()" class="btnColorDark" style="float: right;">
        취소
      </v-btn>
      <v-btn @click="updateBoardContent()" class="btnColorGreen" style="float: right; margin-right: 3px;">
        수정완료
      </v-btn>
      <v-text-field class="inoutSearchInput" style="
     width: 50%;
      background-color: #fff;
      margin: 0 0 0 0;" v-model="boardInfo.hb_title" color="#0f7545" label="제목을 입력해주세요." single-line clearable flat
        hide-details="auto" variant="outlined" density="compact">
      </v-text-field>

      <ckeditor :editor="editor" v-model="boardInfo.hb_content" :config="editorConfig" @ready="onEditorReady">
      </ckeditor>

      <v-file-input v-model="file" density="compact" :label=fileNameSplit variant="underlined" style="margin-top: 15px;"/>

      <v-btn @click="goToUpdateCancle()" class="btnColorDark" style="float: right; margin-top: 10px;">
        취소
      </v-btn>
      <v-btn @click="updateBoardContent()" class="btnColorGreen"
        style="float: right; margin-top: 10px; margin-right: 3px;">
        수정완료
      </v-btn>

    </v-card>
  </template>
  <template v-else>
    <div class="boardView">
      <v-card elevation="3" class="boardViewCard">
        <div v-if="loading" class="spinner tac"></div>

        <div v-else>
          <v-card-item>
            <v-card-title>
              <b>
                {{ boardInfo.hb_title }}
              </b>
              <v-btn v-if="role >= 8 && boardInfo.hm_id === id" @click="goToUpdate()"
                style="float: right; margin:0 0 3px 3px; " class="btnColorBlue">
                수정
              </v-btn>
              <v-btn v-if="role >= 8 && boardInfo.hm_id === id" @click="deleteBoardContent()"
                style="float: right; margin:0 3px 3px 0;" class="btnColorRed">
                삭제
              </v-btn>

            </v-card-title>

            <v-card-subtitle style="border-bottom: 3px solid #c1c1c1; opacity: 1; color: #333;">
              <span style="float: right;">조회: {{ boardInfo.hb_count + 1 }}</span>
              <span style="float: right; margin-right: 15px;">작성자: {{ boardInfo.hm_name }}</span>
            </v-card-subtitle>
          </v-card-item>
          <v-card-text class="boardCardText">
            <div v-html="content"></div>
          </v-card-text>
          <v-card-text >
            <div class="boardFileDownDiv">
              <span>
                첨부파일: 
              </span>
              <span @click="downloadExcelFile()" style="cursor: pointer;"> 
              <v-icon :style="{ color: iconColor(boardInfo.hb_file_name) }">
                {{ getFileIcon(boardInfo.hb_file_name) }}
              </v-icon>
              <span class="boardFileDownSpan" >
                {{ fileNameSplit }}
              </span>
            </span>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </div>
    <div class="boardViewMoveBtn tac">
      <v-btn v-if="boardInfo.hb_code === boardInfo.minCode" class="boardNonMoveBtn" style="float: left;">이전글</v-btn>
      <v-btn v-else @click="goToDown(boardInfo.hb_code)" class="boardMoveBtn" style="float: left;">이전글</v-btn>
      <v-btn @click="boardPage()" class="bigBtn2">목록으로</v-btn>
      <v-btn v-if="boardInfo.hb_code === boardInfo.maxCode" @click="goToUp(boardInfo.hb_code)" class="boardNonMoveBtn" style="float: right;">다음글</v-btn>
      <v-btn v-else @click="goToUp(boardInfo.hb_code)" class="boardMoveBtn" style="float: right;">다음글</v-btn>
    </div>
    </template>
</template>

<script setup lang="ts">
import CKEditor from '@/plugins/ckeditor/build/ckeditor.js';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import {
  mdiFileExcel,
  mdiFileDocument,
  mdiFilePdfBox,
  mdiFileImage,
  mdiFile,
  mdiArchive
} from '@mdi/js'
const editor = CKEditor;
const role = store.getters.role;
const id = store.getters.id;

const loading = ref(false);
const changeUpdate = ref(false);
const boardInfo = ref([] as any);
const fileNameSplit = ref('');
const fileName = ref('');
const file = ref();
const duplicateFileName = ref([] as any[]);

const boardCode = ref('');
const content = ref('');

const boardPage = async () => {
  router.push('/MngBoardPage');
}
const goToUpdate = async () => {
  changeUpdate.value = true;
}
const goToUpdateCancle = async () => {
  changeUpdate.value = false;
}

const goToDown = async (code: any) => {
  if(code === '0001'){
    return;
  }
  let number = parseInt(code, 10);
  number -= 1;
  // 감소된 숫자를 문자열로 변환하고, 원래의 형식으로 패딩
  const downCode = number.toString().padStart(code.length, '0');
  store.commit('setBoardCode', downCode);
  boardCode.value = store.state.boardCode
  await readBoardSelect();
}

const goToUp = async (code: any) => {
  if(code === boardInfo.value.maxCode){
    return;
  }
  let number = parseInt(code, 10);
  number += 1;
  // 감소된 숫자를 문자열로 변환하고, 원래의 형식으로 패딩
  const downCode = number.toString().padStart(code.length, '0');
  store.commit('setBoardCode', downCode);
  boardCode.value = store.state.boardCode
  await readBoardSelect();
}

const removePrefix = (fileName:string) => {
    // 정규식 패턴: 'hwrCount' 뒤에 어떤 문자열이 오더라도 언더스코어를 포함하여 이를 삭제
    const regexPattern = /^.*hC25Jes_/;
    
    // 정규식을 사용하여 접두사 제거
    if (regexPattern.test(fileName)) {
      return fileName.replace(regexPattern, '');
    } else {
      return fileName;
    }
  };
  
const downloadExcelFile = () => {
  if (!boardInfo.value.hb_file_name) {
    return;
  }
  window.location.href = `/api/downloadBoardFile/${boardInfo.value.hb_file_name}`;
};
const iconColor = (fileName: any) => {
  if (!fileName) {
    return 'red';
  }
  const extension = fileName.split('.').pop(); // 확장자 추출
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return 'darkgreen';
    case 'pdf':
      return 'red';
    case 'hwp':
    case 'hwpx':
    case 'hwt':
      return 'orange';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
      return 'blue';
    case 'zip': // zip 파일 추가
    return 'yellow';
    default:
      return 'black';
  }
};
const getFileIcon = (fileName: any) => {
  if (!fileName) {
    return '';
  }
  const extension = fileName.split('.').pop(); // 확장자 추출
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return mdiFileExcel;
    case 'pdf':
      return mdiFilePdfBox;
    case 'hwp':
    case 'hwpx':
    case 'hwt':
      return mdiFileDocument;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
      return mdiFileImage;
    case 'zip': 
      return mdiArchive;
    default:
      return mdiFile;
  }
};

const readBoardSelect = async () => {
  try {
    loading.value = true;
    const response = await axios.get(`/api/readBoardSelect/${boardCode.value}`);
    const responseData = response.data;
    if (responseData) {
      boardInfo.value = responseData;
      content.value = boardInfo.value.hb_content;

      fileNameSplit.value = removePrefix(boardInfo.value.hb_file_name);
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};


const editorConfig = {
  language: 'ko',
};


// CKEditor의 ready 이벤트를 처리할 함수
const onEditorReady = (editorInstance: any) => {
  editorInstance.ui.getEditableElement().parentElement.insertBefore(
    editorInstance.ui.view.toolbar.element,
    editorInstance.ui.getEditableElement()
  );
};

const readBoardDupFileName = async (fileName: string) => {
  try {
    const response = await axios.get(`/api/readBoardDupFileName/${fileName}`);
    const result = response.data
    duplicateFileName.value = result;
    return result;
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};

const saveBoardFile = async () => {
  if(!file.value){
    file.value = '';
    return;
  }
  let files = file.value;
  const formData = new FormData();
  const fileExtension = files.name.split('.').pop(); // 확장자 추출
  const fileNameWithoutExtension = files.name.split('.').slice(0, -1).join('.'); // 확장자 제외 나머지

  // if (fileExtension !== 'xlsx') {
  //   alert('엑셀 파일만 업로드해주세요.')
  // }
  const allowedExtensions = ['xlsx', 'pdf', 'hwp', 'docx', 'pptx', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'zip'];
  if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
    alert('허용되지 않는 파일 형식입니다.');
  }
  fileName.value = fileNameWithoutExtension + '.' + fileExtension;

  await readBoardDupFileName(fileName.value);
  let isDuplicate = duplicateFileName.value.some(item => item.hb_file_name === fileName.value);
  // 중복된 파일 이름이 있다면 숫자를 늘려서 새로운 파일 이름 생성

  if (isDuplicate) {
    let count = 1;
    let baseFileName = fileName.value.split('.').slice(0, -1).join('.'); // 확장자를 제거한 파일 이름
    while (isDuplicate) {
      fileName.value = `${count}hC25Jes_${baseFileName}.${fileExtension}`;
      isDuplicate = duplicateFileName.value.some(item => item.hb_file_name === fileName.value);
      count++;
    }
  }

  let newFile = new File([files], fileName.value); // 새로운 파일 생성
  formData.append('file', newFile);
  try {
    boardInfo.value.hb_file_name = fileName.value;
    await axios.post(`/api/saveBoardFile/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('파일 업로드 오류:', error);
  }

};

// 저장 버튼 클릭 시 호출되는 메서드
const updateBoardContent = async () => {
  const isConfirmed = window.confirm(`해당 게시글을 수정하시겠습니까?`);
  if (isConfirmed) {
  try {
    loading.value = true;
    await saveBoardFile();
    const response = await axios.post(`/api/updateBoardContent`, {
      boardInfo: boardInfo.value
    });
    const result = response.data
    if (result.success) {
      await readBoardSelect();
      await goToUpdateCancle();
    } else {
      alert('수정에 실패하였습니다. 관리자에게 문의해주세요.')
      console.log('데이터 저장 실패');
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    loading.value = false;

  }
}else{
  return;
}

};
// 저장 버튼 클릭 시 호출되는 메서드
const deleteBoardContent = async () => {
  const isConfirmed = window.confirm(`해당 게시글을 삭제하시겠습니까?`);
  if (isConfirmed) {
    try {
      loading.value = true;

      const response = await axios.post(`/api/deleteBoardContent/${store.state.boardCode}`);
      const result = response.data
      if (result.success) {
        store.commit('setBoardCode', '');
        await boardPage();
      } else {
        alert('수정에 실패하였습니다. 관리자에게 문의해주세요.')
        console.log('데이터 저장 실패');
      }
    }
    catch (error) {
      console.error(error);
      throw error; // 에러 다시 던지기
    } finally {
      loading.value = false;
    }
  } else {
    return;
  }

};
onMounted(async () => {
  boardCode.value = store.state.boardCode
  await readBoardSelect();
})
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>