<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" disable-resize-watcher>
      <v-list style="margin: 0; padding: 0;" class="SslayoutNav">
        <v-list-item
          v-if="role === 3"
          :style="listItemStyle"
          @click="navigateMain()"
          class="fontNotoSans700"
          :prepend-icon="mdiHomeImportOutline"
        >
          메인
        </v-list-item>
        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              v-show="role === 3"
              class="fontNotoSans700 "
              :prepend-icon="mdiOfficeBuildingOutline "
            >
              기관관리
            </v-list-item>
          </template>
            <v-list-item
              v-for="comp in compSec"
              :key="comp.title"
              class="fontNotoSans500 SsListItem"
              @click="navigateTo('/SsCompPage', comp)"
              :class="{ 'active-link': currentPageTitle === comp.title }"
              :prepend-icon="comp.icon"
            >
            {{ comp.title }}
            </v-list-item>
        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              v-show="role === 3"
              class="fontNotoSans700 "
              :prepend-icon="mdiFaceMan "
            >
              사원관리
            </v-list-item>
          </template>
          <v-list-item
              v-for="emp in empSec"
              :key="emp.title"
              class="fontNotoSans500 SsListItem"
              @click="navigateTo('/SsEmpPage', emp)"
              :class="{ 'active-link': currentPageTitle === emp.title }"
              :prepend-icon="emp.icon"
            >
            {{ emp.title }}
            </v-list-item>
         
        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              v-show="role === 3"
              class="fontNotoSans700 "
              :prepend-icon="mdiCashRegister"
            >
              재무회계
            </v-list-item>
          </template>
      

        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              v-show="role === 3"
              class="fontNotoSans700 "
              :prepend-icon="mdiRice "
            >
              위탁급식
            </v-list-item>
          </template>
       

        </v-list-group>


      </v-list>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>{{ currentPageTitle }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <span class="mngNameRole">{{ name }}</span>
    </v-app-bar>

    <v-main>
      <v-sheet class="mgtSheetBody">

        <slot />
        </v-sheet>
    </v-main>
    <v-footer color="#191919" class="pa-0 footer">
      <v-container>
        <v-row>
          <v-col cols="4" style="align-items: center; display: flex; justify-content: center; ">
            <img class="mainLayoutHeaderLogo" @click="indexPage" src="@/assets/pictures/hamwooriLogo.png"
              style="width:100px; height: auto; cursor: pointer;" />
          </v-col>
          <v-col cols="8" class="white--text">
                <div class="grey--text text--lighten-1" style="font-size: 12px;">
                  주식회사 함우리 | 사업자등록번호 : 185-81-02287 | 주소 : 서울시 송파구 법원로90, 전북특별자치도 전주시 덕진구 기지로77
                </div>
                <div class="grey--text text--lighten-1" style="font-size: 12px;">
                  이메일 : hamwoori25@naver.com | 전화번호 : 1833-7489
                </div>
                <div class="grey--text text--lighten-2" style="font-size: 10px;">
                  Copyright © 2024 Hamwoori. All Rights Reserved.
                  <span class="grey--text text--lighten-2">Design by Hamwoori</span>
                </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style scoped>
.active-link {
  background-color: #ddd; /* 원하는 활성화된 링크의 배경색을 여기에 설정하세요 */
}
.v-list-item--active{
  background-color: #333;
  color: #fff;
}
.smListValue:last-child{
  border-bottom: 2px solid #d9d9d9;
}
.SslayoutNav{
  display: flex;
  flex-direction: column;
  height: calc(100vh); /* Viewport height */
  overflow-y: auto;
}
</style>

<script setup>
import store from '@/store';
import { ref, computed, watch, onMounted} from 'vue'
import router from '@/router';
import {
  mdiHomeImportOutline,
  mdiFileDocumentEdit,
  mdiOfficeBuildingOutline,
  mdiAccountTieOutline,
  // mdiFileSign,
  // mdiIsland,
  // mdiSack,
  // mdiCurrencyKrw,
  mdiMonitorAccount,
  mdiOfficeBuildingCog,
  mdiFaceMan,
  mdiInformationSlabCircleOutline,
  mdiCashRegister,
  mdiRice,
} from '@mdi/js'
const name = store.state.name;
const role = store.getters.role;
const drawer = ref(false);

// const currentPageTitle = ref(localStorage.getItem('currentPageTitle') || '관리자페이지');
const currentPageTitle = ref(store.state.MngTitle || '관리자페이지');

const listItemStyle = {
  letterSpacing: '-0.5px',
  fontSize: '20px',
  height: '64px',
  backgroundColor: 'darkgreen',
  color: 'white'
};


const navigateTo = (path, comp) => {
  router.push(path);
  currentPageTitle.value = comp.title;
  store.commit('setMngTitle', comp);
  drawer.value = true; // Optional: Close the drawer after navigation
};
const navigateMain = () => {
  store.commit('setMngTitle', {title: '메인', link: ''});
  router.push('/SsIndexPage');
}
const compSec = [
  { title: '서비스관리', link: 'SsService', icon: mdiFileDocumentEdit },
  { title: '담당자설정', link: 'SsDamdang', icon: mdiAccountTieOutline },
  { title: '설정', link: 'SsCog', icon: mdiOfficeBuildingCog },
];
const empSec = [
  { title: '사원정보', link: 'SsEmpList', icon: mdiInformationSlabCircleOutline },
  { title: '임금기초', link: 'SsAttendance', icon: mdiMonitorAccount },
  // { title: '임금대장', link: 'SsWage', icon: mdiCurrencyKrw },
  // { title: '휴가관리', link: 'SsDayOff', icon: mdiIsland },
  // { title: '퇴직급여', link: 'SsRetire', icon: mdiSack },
  // { title: '계약서', link: 'SsContract', icon: mdiFileSign },
];

const changeMngTitle = computed(() => {
  const MngTitleComputed = store.state.MngTitle
  return MngTitleComputed;
});

watch(changeMngTitle, async (newValue) => {
  if (newValue) {
    currentPageTitle.value = newValue.title;
  }
});

onMounted(() => {
  const storedTitle = store.state.MngTitle.title;
  // const storedTitle = localStorage.getItem('currentPageTitle');
  if (storedTitle) {
    currentPageTitle.value = storedTitle;
  }
});
</script>
