<template>
  <v-app id="inspire" style="background-color: #fcfdff;">
    <v-app-bar id="LayoutHeader">
      <v-container class="mainLayoutHeaderContainer mx-auto d-flex align-center justify-center">
        <img class="mainLayoutHeaderLogo" @click="indexPage" src="@/assets/pictures/hamwooriLogo.png"
          style="width:100px; height: 100%; cursor: pointer;" />
        <v-spacer></v-spacer>
        <p id="mainLayoutHiMent" class="hide-L" style="font-size: 20px; opacity: 0.8;"></p>
        <v-spacer></v-spacer>
        <InquiryForm />
        <div class="mg-right10"></div>
        <v-btn v-if="(role === 3)" @click="SsIndexPageMove" class="btnColorBlue mg-right10">기관관리페이지</v-btn>
        <v-btn v-if="(role >= 7)" @click="mngIndexPageMove" class="btnColorBlue mg-right10">관리자페이지</v-btn>
        <v-btn v-if="(role >= 8)" class="btnColorPink mg-right10" @click="openCrawlingPage">크롤링</v-btn>
        <v-btn class="btnColorDark" @click="logRemote">{{ isLoggedIn ? '로그아웃' : '로그인' }}</v-btn>

      </v-container>
    </v-app-bar>

    <v-main id="LayoutBody">
      <v-container class="mainContainer">
        <slot />
      </v-container>
    </v-main>
    <v-footer color="#191919" class="footer">
      <v-container class="footerContainer">
        <v-row>
          <v-col cols="2" style="align-items: center; display: flex; justify-content: center; ">
            <img class="mainLayoutHeaderLogo footerLogo" @click="indexPage" src="@/assets/pictures/hamwooriLogo.png"
              style="height: auto; cursor: pointer;" />
          </v-col>
          <v-col cols="10" class="white--text">
                <div class="grey--text text--lighten-1" >
                  주식회사 함우리 | 사업자등록번호 : 185-81-02287 | 주소 : 서울시 송파구 법원로90, 전북특별자치도 전주시 덕진구 기지로77
                </div>
                <div class="grey--text text--lighten-1" >
                  이메일 : hamwoori25@naver.com | 전화번호 : 1833-7489
                </div>
                <div class="grey--text text--lighten-2">
                  Copyright © 2024 Hamwoori. All Rights Reserved.
                  <span class="grey--text text--lighten-2">Design by Hamwoori</span>

                </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script setup lang="ts">
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import InquiryForm from '../wage/InquiryForm.vue';
const account = ref({
  id: null,
  name: '',
  role: '',
});
const isLoggedIn = store.getters.isLoggedIn;
const role = store.getters.role;

const indexPage = () => {
  router.push('/');
};
const LoginPage = () => {
  router.push('/LoginPage');
};
const openCrawlingPage = () => {
  router.push('/CrawlingPage');
};
const logout = () => {
  axios.delete("/api/account").then(() => {
    alert("로그아웃하였습니다.");
    account.value.id = null;
    account.value.name = "";
    account.value.role = "";
    // sessionStorage.removeItem('vuex_state');
    // sessionStorage.removeItem('mainDateInfo');
    // sessionStorage.removeItem('selected_member');
    // sessionStorage.removeItem('mngOneClient');
    // sessionStorage.removeItem('wageDirectPushData');
    // sessionStorage.removeItem('wageDirectPushBox');
    // sessionStorage.removeItem('status2Form');
    // sessionStorage.removeItem('mng2Form');
    // sessionStorage.removeItem('getBoardCode');
    // sessionStorage.removeItem('randomCode');
    // sessionStorage.removeItem('crawl_Result_List');
    // sessionStorage.removeItem('SsInfoBox');
    // sessionStorage.removeItem('MngIntoSs');
    // sessionStorage.removeItem('getMngTitle');
    // sessionStorage.removeItem('getSsYm');
    // sessionStorage.removeItem('getSsECDEmpInfo');
    // sessionStorage.removeItem('getSsECDBox');
    sessionStorage.clear();

    window.location.reload();
  });
};

// 기관관리 새창 

const SsWindow = ref();
const SsIndexPageMove = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1); // 화면 너비의 5분의 3
  const height = Math.round(screenHeight * 1); // 화면 높이의 5분의 3

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`;

// 관리 페이지 URL
const url = "#/SsIndexPage";
  // 새 창 열기 전에 로컬 스토리지에 페이지 제목 저장
  // localStorage.setItem('currentPageTitle', '메인');
  store.commit('setMngTitle', '메인');

  // 기존에 'Mng'가 포함된 URL의 창이 열려 있는지 확인
  if (SsWindow.value && !SsWindow.value.closed) {
    // 창이 열려 있고 'Mng'가 포함된 URL이라면 해당 창을 맨 앞으로 가져옴
    SsWindow.value.focus();
  } else {
    // 'Mng'가 포함된 URL의 창이 없거나 창이 닫혀 있으면 새 창을 열음
    SsWindow.value = window.open(url, "_blank", options);
  }
};

// 새 창을 관리할 ref 변수
const mngWindow = ref();
const mngIndexPageMove = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1);
  const height = Math.round(screenHeight * 1); 

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}, fullscreen=yes`;

// 관리 페이지 URL
const url = "#/MngIndexPage";
  // 새 창 열기 전에 로컬 스토리지에 페이지 제목 저장
  // localStorage.setItem('currentPageTitle', '관리자페이지');
  store.commit('setMngTitle', '관리자페이지');

  // 기존에 'Mng'가 포함된 URL의 창이 열려 있는지 확인
  if (mngWindow.value && !mngWindow.value.closed) {
    // 창이 열려 있고 'Mng'가 포함된 URL이라면 해당 창을 맨 앞으로 가져옴
    mngWindow.value.focus();
  } else {
    // 'Mng'가 포함된 URL의 창이 없거나 창이 닫혀 있으면 새 창을 열음
    mngWindow.value = window.open(url, "_blank", options);
  }
};

const logRemote = () => {
  if (isLoggedIn) {
    logout();
  } else {
    LoginPage();
  }
};

onMounted(() => {
  store.getters.isLoggedIn;
  mngWindow.value = null;
})
</script>